<template>
    <main-confirm @confirm="$emit('closeModal')" @cancel="$emit('closeModal')" confirm-title="product.error_title" :description="description" oneOption>
        <icon-warning :size="60"/>
    </main-confirm>
</template>
<script>
import IconWarning from '@/common/svg/iconWarning.vue'
import MainConfirm from '@/common/components/modals/MainConfirm.vue'

export default {
    name: "ErrorReviewQuestion",
    components: { MainConfirm, IconWarning, },
    props: {
        description: {
            type: String
        },
    }
}
</script>