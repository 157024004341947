<template>
    <div class="comment-question pe-sm-1 pe-md-0">
        <a v-if="showReviews" class="d-flex align-items-center me-md-2 pe-2 color-dark-gray-200" @click.prevent="$emit('toggleReviews')"> 
            <icon-comment class="me-2" color="var(--gray-1001)" style="margin-top: 3px;"/>
            {{$t('product.leave_your_comment')}}
        </a>
        <a class="d-flex align-items-center color-dark-gray-200 ms-auto ms-md-3 ms-lg-auto" @click.prevent="$emit('toggleQuestions')">
            <icon-customer-service class="me-2" :color="'var(--gray-1001)'"/>
            {{$t('product.make_a_question')}}
        </a>
    </div>
</template>

<script>

import IconComment from '../../assets/svg/iconComment.vue'
import IconCustomerService from '../../../layout/assets/svg/IconCustomerService.vue'

export default {
    name: 'ToggleReviewQuestionForm',
    components: { IconComment, IconCustomerService },
    props: {
        showReviews: {
            type: Boolean
        },
    }
}
</script>

<style scoped>
    .comment-question > a {
        font-size: 14px;
    }
    .comment-question > a {
        transition: all .15s ease-in-out;
    }
    .comment-question > a:hover {
        color: var(--main-red) !important;
    }
    .comment-question > a > svg {
        transition: fill .05s ease-in-out;
    }
    .comment-question > a:hover > svg {
        fill: var(--main-red);
    }
    @media (min-width : 992px) {
        .comment-question > a {
            font-size: 16px;
        }
    }
    @media (max-width : 1200px) {
        /* .comment-question {
            width: 100%;
        } */
    }
</style>
