<template>
    <svg  :width="size + 'px'" :fill="color" viewBox="0 0 16.05 15.381">
        <g id="Group_4048" data-name="Group 4048" transform="translate(-1083.988 -2643.646)">
            <g id="Group_2836" data-name="Group 2836" transform="translate(1083.988 2643.646)">
                <path id="Path_1241" data-name="Path 1241" d="M1090.794,2657.344v3.1a.5.5,0,0,0,.905.3l1.814-2.469Z" transform="translate(-1084.943 -2645.566)" fill="#fff"/>
                <path id="Path_1242" data-name="Path 1242" d="M1099.827,2643.739a.5.5,0,0,0-.523-.036l-15.046,7.858a.5.5,0,0,0,.07.919l4.183,1.43,8.908-7.617-6.894,8.3,7.011,2.4a.5.5,0,0,0,.422-.045.5.5,0,0,0,.236-.355l1.839-12.372A.5.5,0,0,0,1099.827,2643.739Z" transform="translate(-1083.988 -2643.646)" fill="#fff"/>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
name: 'iconSearch',
components: {},
props: {
    size: {
        type: Number,
        default: 20
    },
    color: {
        type: String,
        default: 'white'
    },
    
}
}
</script>

<style>

</style>