<template>
    <icon-base :width="size + 'px'" :fill="color" viewBox="0 0 232.398 249">
        <path id="Icon_material-title" data-name="Icon material-title" d="M-252.59-529.326v49.806h91.3v199.194h49.806V-479.52h91.289v-49.806Z" transform="translate(252.59 529.326)"/>
    </icon-base>
</template>

<script>
export default {
name: 'iconTitle',
components: {
    iconBase: () => import('@/common/components/iconBase.vue')
},
props: {
    size: {
    type: Number,
    default: 20
    },
    color: {
        type: String,
        default: ''
    },
    enableBackground: {
    type: Boolean
    }
}
}
</script>
