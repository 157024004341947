<template>
<div>
    <transition name="product-rate-form" out-in>
        <form @submit.prevent="send" class="pb-5" :class="{'low-opacity': sendBtnPressed}">
            <div v-if="!inOrderDetails" class="d-flex flex-column flex-xl-row align-items-xl-center mb-3 position-relative">
                <h5 class="title-18 cursor-pointer mb-0" @click.prevent="toggleReview"> 
                    <icon-edit :size="18" :color="'var(--gray-1001)'" class="me-2" style="margin-bottom: 6px;" /> {{editData ? $t('product.edit_review'):$t('product.create_review')}}
                </h5>
                <toggle-review-question-form class="d-flex justify-content-end justify-content-between ms-auto mt-3 mt-xl-0"
                    :showReviews="!hasUserReview" @toggleReviews="toggleReview" @toggleQuestions="$emit('formQuestion')" />
            </div>
            <main-input :placeholder="$t('product.review_title')" :title="$t('product.title')" class="mb-4" id="input-review" :max="65" ref="title" 
                :value="form.title" @changeValue="(data) => {form.title = data}">
                <icon-title :color="'var(--dark-gray-200)'" :size="15"/>
            </main-input>
            <h6 class="title-15 mb-1">{{$t('product.qualify')}}</h6>
            <div>
                <star-rating @rating-selected="(n) => (form.rating = n)" :rating="form.rating" :showRating="false" :starSize="24" :readOnly="false" :increment="0.5" :key="starRating"/>
            </div>
            <div class="form-group mt-3">
                <main-input textArea ref="comment" id="comment-review" class="mb-4" :placeholder="$t('product.review_placeholder')" :title="$t('product.review')" :maxCharacterLength="155" :max="155"
                :value="form.comment" @changeValue="(data) => { form.comment = data}">
                    <template #reviewArea>
                        <span class="size-15 color-dark-gray-200 weight-500 ms-2">(155 {{$t('product.characters')}})</span> 
                        <span class="size-15 weight-500 ms-auto" :class="[form.comment.length === 155 ? 'color-dark-main-red':'color-dark-gray-200']">{{form.comment.length}} / 155</span>
                    </template>
                </main-input>
            </div>
            <h6 class="title-15 mt-3">
                {{$t("product.add_a_photo_or_video")}} 
                <span class="color-dark-gray-200 weight-500">
                    ({{$t('product.optional')}})
                </span> 
            </h6>
            <vue-dropzone ref="dropzoneReview" id="img-drop-zone" class="position-relative mt-3" :options="dropzoneOptions" :max-file-size-in-m-b="20" :max-number-of-files="6"
            @vdropzone-file-added="afterComplete" @vdropzone-removed-file="removedFile">
            </vue-dropzone>
            <div class="d-flex mt-4 position-relative" style="z-index: 3;">
                <div class="custom-tooltip" :class="[showTooltip && !validEdit ? 'd-block' : 'd-none']">
                    {{$t("product.too_many_photos")}}
                </div>
                <div class="d-flex ms-auto" @mouseover="showTooltip = true" @mouseleave="showTooltip = false">
                    <second-button id="cancel" class="me-3" :title="$t('auth.cancel')" @click="$emit('cancelReview')" type="button" />
                    <main-button id="send" :disabled="!validEdit" :title="editData ? 'product.edit':'home.contact.send'" icon>
                        <icon-send class="me-3" :size="15" />
                    </main-button>
                </div>
            </div>
        </form>
    </transition>
    <error-review-question v-if="error" @closeModal="reload" description="product.error_review" />
</div>
</template>
<script>
import MainInput from '../../../common/components/inputs/MainInput.vue'
import IconTitle from '../../../common/svg/iconTitle.vue'
import IconEdit from '../../checkout/assets/svg/iconEdit.vue'
import ToggleReviewQuestionForm from "./buttons/ToggleReviewQuestionForm.vue"
import StarRating from './rating/StarRating.vue'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import vueDropzone from 'vue2-dropzone'
import MainButton from '../../../common/components/buttons/MainButton.vue'
import IconSend from '../../../common/svg/iconSend.vue'
import { required, maxLength} from 'vuelidate/lib/validators'
import { mapGetters } from "vuex"
import { ProductService } from '@/modules/products/services/product-service.js'
import SecondButton from '../../../common/components/buttons/SecondButton.vue'

import ErrorReviewQuestion from "@/modules/products/components/modals/ErrorReviewQuestion.vue";
const service = new ProductService()

export default {
    components: {
        IconEdit, 
        IconTitle, 
        IconSend,
        MainButton, 
        MainInput, 
        ToggleReviewQuestionForm, 
        StarRating, 
        vueDropzone,
        SecondButton, 
        ErrorReviewQuestion
    },
    name: "CreateReview",
    data() {
        return {
            changed: false,
            form: {
                title: '',
                rating: 0,
                comment: '',
                images: [],
                new_images: [],
                images_to_delete:[],
                cod_articulo: null
            },
            dropzoneOptions: {
                url: '/',
                maxFiles: 6,
                thumbnailWidth: 200,
                resizeQuality: .6,
                addRemoveLinks: true,
                autoProcessQueue: false,
                addedfiles: (file) => { this.afterComplete(file) },
                dictDefaultMessage: this.uploadPlaceholder(this.$t('product.upload_image'), this.$t('product.upload_image_description')),
                resizeWidth: 1000,
                resizeHeight: 1000,
                acceptedFiles: ".jpeg, .jpg, .png"
            },
            originalForm: null,
            oldImgs: [],
            starRating: 0,
            //timing the opacity animation
            showTooltip: false,
            validEdit: true,            
            sendBtnPressed: false,
            error: false,
        }
    },
    validations: {
        form: {
            comment: {
                required,
                maxLength: maxLength(300)
            },
            title: {
                required,
                maxLength: maxLength(300)
            },
        }
    },
    props: {
        code: {
            type: Number
        },
        editData: {
            type: Object,
            default() {
                return {
                    title: '',
                    rating: 0,
                    comment: '',
                    images: [],
                    new_images: [],
                    delete_images: [],
                    cod_articulo: null
                }
            }
        },
        hasUserReview: {
            type: Boolean
        },
        inOrderDetails: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters({
            disableModal: "common/disableModal",
        })
    },
    watch: {
        '$i18n.locale':'translateDelete',
        form: {
            handler(val) {
                this.changed = JSON.stringify(val) !== JSON.stringify(this.originalForm);
            },
            deep: true
        },
    },
    methods: {
        uploadPlaceholder(title, description){
            return `<div>
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Checkout" x="0px" y="0px" viewBox="0 0 250 250" style="width:50px; fill:var(--gray-1001)" xml:space="preserve">
                    <g xmlns="http://www.w3.org/2000/svg" id="Group_4680" data-name="Group 4680" transform="translate(531.674 -23.232)">
                        <path id="Path_2174" data-name="Path 2174" d="M-401.2,142.005a9.187,9.187,0,0,0-1.1-1.334,4.653,4.653,0,0,0-6.481-.115c-1.867,1.7-3.587,3.557-5.392,5.334a5,5,0,0,1-6.289,1.209,8.621,8.621,0,0,1-2.28-2.107c-2.7-3.44-5.334-6.93-8-10.406-4.08-5.334-8.147-10.668-12.25-16-2.418-3.138-5.046-2.884-6.868.618l-.048.1q-10.175,20.15-20.336,40.3a8.576,8.576,0,0,0-.747,2.022,2.868,2.868,0,0,0,2.024,3.514l.061.016a9.788,9.788,0,0,0,2.667.3q18.588.028,37.183,0h37.729c1.414,0,2.938,0,3.707-1.467s-.106-2.725-.916-3.885Q-394.84,151.041-401.2,142.005Z"/>
                        <path id="Path_2175" data-name="Path 2175" d="M-413.628,115.935a12.132,12.132,0,0,0,12.18-12.081,12.133,12.133,0,0,0-12.082-12.18,12.131,12.131,0,0,0-12.179,12.082A12.138,12.138,0,0,0-413.628,115.935Z"/>
                        <path id="Path_2176" data-name="Path 2176" d="M-321.82,148.473a39.147,39.147,0,0,0-39.148,39.147,39.147,39.147,0,0,0,39.146,39.147,39.147,39.147,0,0,0,39.148-39.146h0A39.147,39.147,0,0,0-321.82,148.473Zm17.386,42.261a3.158,3.158,0,0,1-.456.033h-13.694a.089.089,0,0,0-.089.089v13.695a3.152,3.152,0,0,1-3.159,3.144,3.022,3.022,0,0,1-.446-.033,3.316,3.316,0,0,1-2.668-3.355V190.851a.089.089,0,0,0-.089-.089h-13.7a3.151,3.151,0,0,1-3.144-3.159,3.042,3.042,0,0,1,.033-.446,3.319,3.319,0,0,1,3.355-2.667h13.456a.089.089,0,0,0,.088-.089v-13.7a3.152,3.152,0,0,1,3.16-3.144,3.187,3.187,0,0,1,.446.032,3.316,3.316,0,0,1,2.667,3.356V184.4a.089.089,0,0,0,.089.089h13.455a3.32,3.32,0,0,1,3.356,2.668A3.152,3.152,0,0,1-304.434,190.734Z"/>
                        <path id="Path_2177" data-name="Path 2177" d="M-340.048,141.706a49.3,49.3,0,0,1,10.963-2.92v0a103.018,103.018,0,0,0,.947-13.74A101.769,101.769,0,0,0-429.858,23.232a101.767,101.767,0,0,0-101.816,101.72A101.769,101.769,0,0,0-429.953,226.768a101.767,101.767,0,0,0,62.139-21.137A49.283,49.283,0,0,1-340.048,141.706Zm-34.313,22.6a13.926,13.926,0,0,1-.706,4.351,12.444,12.444,0,0,1-12.241,8.445h-65.386c-6.667,0-13.3.032-19.954,0a12.111,12.111,0,0,1-11.059-6.4,28.115,28.115,0,0,1-1.675-4.649V84.065a1.788,1.788,0,0,0,.173-.4A12.5,12.5,0,0,1-472.425,73H-387.2a12.513,12.513,0,0,1,12.854,12.162c.005.212.006.423,0,.635Q-374.306,125.042-374.361,164.3Z"/>
                    </g>
                </svg>
                <h6 id="placeholder-title" class="m-0 my-1 title-16">${title}</h6>
                <span id="placeholder-description" style="color: #a5b2b7; font-family: 'Rubik'; font-weight: 300;">${description}</span>
            </div>`
        },
        async afterComplete(upload) {
            try {
                for (let i = 0; i < upload.length; i++) {
                    this.form.new_images.push({ name: upload[i].name, file: upload[i] });
                }
                this.calculateHeight();
                this.checkFileCount();
            } catch (error) {
                console.log(error)
            }
            this.translateDelete();
        },   
        async send() {
            this.form.cod_articulo = this.code;
            this.$refs.title.$v.$touch();
            this.$refs.comment.$v.$touch();
            this.$v.$touch();
            if (this.$refs.title.$v.$invalid || this.$refs.comment.$v.$invalid || this.$v.$invalid) return;
            // if we haven't changed the form or add / delete the images, then don't make the request.
            if (!this.changed && !(this.editData.new_images.length > 0 || this.editData.images_to_delete.length > 0)) { this.$emit('formReview');  return;}
            try {
                this.sendBtnPressed = true;
                let req = this.editData ? await service.editReview(this.editData.id, this.form): await service.createReview(this.code, this.form);
                if(req.data.code == 4005){
                    let notify = { text: this.$t('product.review_update'), type: 'success', show: true };
                    this.$store.commit("common/SET_SHORT_NOTIFY", notify);
                }else {
                    let notify = { text: this.$t('product.saved_review'), type: 'success', show: true };
                    this.$store.commit("common/SET_SHORT_NOTIFY", notify);
                }
                if (req.data.code !== 4005 && req.data.code !== 200) {
                    this.error = true;
                    return;
                }
                this.$refs.title.$v.$reset();
                this.$v.$reset();
                this.starRating += 1;
                this.$refs.dropzoneReview.removeAllFiles();

                this.form = { title: '', rating: 0, comment: '', images: [], new_images: [], images_to_delete:[], cod_articulo: null }

                this.editData ? this.$emit('updateReviews') : this.$emit('updateReviews', 'created');
                this.$emit('formReview')
            } catch (e) {
                this.error = true;
                console.log(e);
            } finally {
                this.sendBtnPressed = false;
            }
        },
        calculateHeight() {
            this.$emit('changeHeight', this.$refs.dropzoneReview.$el.clientHeight);
        },
        removedFile(file){
            if (this.editData){
                this.form.images.map((img, index) => { //to delete the images that were stored on the backed
                    if (img === file.name) this.form.images_to_delete.push(index);
                });
            } 
            this.form.new_images.map((img, index) => { //to delete the images that we have store on the dropzone
                if (img.name === file.name) {
                    this.form.new_images.splice(index, 1); // it tends to delete the last image...
                }
            });
            // validating our file count 
            this.checkFileCount();
            //managing styles
            this.calculateHeight();
        },
        checkFileCount(){
            this.validEdit = this.$refs.dropzoneReview.dropzone.files.length <= 6;
            if (this.validEdit) { //removing the mark of the elements that we dont need
                for (const element of document.getElementsByClassName('dz-error-mark')) { element.remove() }
                for (const element of document.getElementsByClassName('dz-error-message')) { element.remove() }
            }
        },
        toggleReview(){ //since we are using the v-show directive to animate this components instance we now need to completely erase the form before continuing.
            this.$emit('formReview');
            this.form = {
                title: '',
                rating: 0,
                comment: '',
                images: [],
                new_images: [],
                images_to_delete:[],
                cod_articulo: null
            }
        },
        async translateDelete(){
            const language = this.$i18n._vm.locale; // translating hover to delete dropzone-files
            for (const element of document.getElementsByClassName('dz-remove')) { //translating delete
                element.innerText = language === 'es' ? 'Quitar imagen':'Remove file';
            }
            // translating dropzone-placeholder
            document.getElementById('placeholder-title').innerText = language === 'es' ? 'Subir imagen':'Upload image'
            document.getElementById('placeholder-description').innerText = language === 'es' ? 'Arrastre o cargue la imagen deseada':'Drop files here to upload'
            for (const element of document.getElementsByClassName('dz-error-message')) {
                element.innerHTML = `<span class="color-white">Error</span>`;
            }
        },
        showError(){ this.error = true },
        reload(){ this.$router.go() }
    },
    created(){ // initialize the edit data in case that it's not null
        if (this.editData != null) {
            this.form = this.editData;
            this.form['images_to_delete'] = [];
            this.form['new_images'] = [];
        }
    },
    mounted(){
        this.form.images.map((image) => {
            const file = { size: 123, name: image, type: "image/png" };
            const url = image;
            this.$refs.dropzoneReview.manuallyAddFile(file, url);
        });
        this.$nextTick().then(() => {
            this.calculateHeight();
        })
        this.translateDelete();
        this.originalForm = {...this.form};
        
    },
}
</script>

<style scoped>
    form {
        transition: var(--transition-1);
    }
    .low-opacity {
        pointer-events: none;
        opacity: 0.5 !important;
    }
    .form-group > .main-input::v-deep textarea {
        width: 100%;
        height: 8rem;
        color: var(--main-navy);
        padding-left: 15px;
        padding-top: 12px;
        border-left: 1px solid !important;
        border-radius: 10px !important;
        border-color: var(--gray-100) !important;
        font-size: 14px;
        resize: none;
    }
    .form-group > .main-input::v-deep span#basic-addon1 {
        display: none;
    }
    .form-group > .main-input::v-deep textarea:focus {
        outline: none;
    }   
    .form-group > .main-input::v-deep textarea.is-invalid-input {
        border-color: var(--main-red) !important;
    }   
    .vue-dropzone {
        border: none;
        border-radius: 15px;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='%23C6D4DBFF' stroke-width='3' stroke-dasharray='16' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        z-index: 2;
    }
    .vue-dropzone::v-deep:hover .dz-default > span > div > svg > g > #Path_2176 {
        fill: var(--blue-600) !important;
    }
    .vue-dropzone::v-deep .dz-default > span > div > svg > g > #Path_2176 {
        transition: var(--transition-1);
    }
    .vue-dropzone::v-deep:hover .dz-default > span > div > svg {
        fill: #c6d4dbd9 !important;
    }
    .vue-dropzone::v-deep:hover .dz-remove {
        transform: translate(-50%, -50%);
    }
    .vue-dropzone::v-deep .dz-remove {
        top: 50% !important;
        left: 50% !important;
        min-width: 120px;
        height: 30px;
        transform: translate(-50%, -10%);
        text-transform: unset;
        border-radius: 5px;
        margin-left: 0 !important;
    }
    .custom-tooltip {
        position: absolute;
        right: 0;
        top: -28px;
        font-size: 11px;
        color: #fff;
        background: #414146eb;
        border-radius: 4px;
        padding: 1px 10px;
        animation: custom-tooltip 0.3s;
    }
    @keyframes custom-tooltip {
        from {
            opacity: 0;
            transform: translateY(5px);
        }to {
            transform: translateY(0);
            opacity: 1;
        }
    }
    .custom-tooltip::after {
        content: '';
        position: absolute;
        right: 12px;
        bottom: -7px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #414146eb;
    }

    /* fixing dropzone's depth */
    .vue-dropzone::v-deep > .dz-preview {
        z-index: 4;
    }
    .toggler-container {
        width: 60px;
        height: 26px;
    }
    .comment-question {
        right: 0;
    }
    @media (min-width : 1400px) {
        .comment-question{
            top: 0;
        }
    }
</style>
